import "./App.css";
import Aura from "./routes/Aura";
import Breakfast from "./routes/Breakfast";
import { Route, Routes } from "react-router-dom";
import RoomService from "./routes/RoomService";
import Lobby from "./routes/Lobby";
import Minibar from "./routes/Minibar";
import Spa from "./routes/Spa";
import ScrollToTop from "../src/components/ScrollToTop/scrolltotop";
import Feedback from "./routes/Feedback";


function App(props) {



  return (
    <div className="App">
      <ScrollToTop><Routes>
        <Route path="/" element={<Aura />}></Route>

        <Route path="/lobby" element={<Lobby />}></Route>
        <Route path="/breakfast" element={<Breakfast />}></Route>
        <Route path="/aura" element={<Aura />}></Route>


        <Route path="/room" element={<RoomService />}></Route>
        <Route path="/minibar" element={<Minibar />}></Route>
        <Route path="/massage" element={<Spa />}></Route>
        <Route path="/feedback" element={<Feedback />}></Route>

      </Routes></ScrollToTop>

    </div>
  );
}


export default App;
