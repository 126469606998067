import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header/Header";
import { NavLink } from "react-router-dom";
import "../App.css";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TabComponent from "../components/TabComponents/TabComponent";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Feedback = ({ currentLanguageCode }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [hijyen, setHijyen] = useState("");
  const [genel, setGenel] = useState(" ");
  const [lezzet, setLezzet] = useState(" ");

  const [userInfo, setuserInfo] = useState(" ");
  const [tel, setTel] = useState(" ");
  const [subject, setSubject] = useState(" ");
  const [from, setFrom] = useState(" ");
  const [message, setMessage] = useState(" ");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (e) => {
    setHijyen(e.target.value);
  };
  const handleClickGenel = (e) => {
    setGenel(e.target.value);
  };
  const handleClickLezzet = (e) => {
    setLezzet(e.target.value);
  };

  const groupedMenuItems = menuItems.reduce((acc, item) => {
    const category = item.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  const form = useRef();
  const handleSubmit = async (e) => {
  
  };

 

  const handleClickButton = async (e) => {
    e.preventDefault();
    let a = "a";
    const FD = new FormData(form.current);
    const obj = {
      message:
        "<strong>Değerlendirme:</strong>" +
        "<br>" +
        genel +
        "<br>" +
        hijyen +
        "<br>" +
        lezzet +
        "<br>" +
        "<br>" +
        "<strong>Mesaj:</strong>" +
        "<br>" +
        message,
      userInfo: userInfo,
      from: from,
      subject: subject,
      tel: tel,
      ownerName: "crowneplazaankarahotel@gmail.com",
      ownerPassword: "rtfwmzrdcyitpixg",
      to: "info@crowneplazaankarahotel.com",
      host: "smtp.gmail.com",
      service: "gmail",
    };
   
    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", obj, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
       
        toast.success("Mesajınız gönderildi.", { autoClose: 1500 });
      }
    } catch (error) {
     
      toast.error(error.message, { autoClose: 1500 });
    }
  };

  return (
    <div>
      <div className="body-bg" />
      <Header />

      <div className="page-content header-clear-large">
        <div className="page-content-scroll">
          <div className="content">
            <div className="decoration-fancy">
              <strong />
              <span className="rate-svg">
                <AiFillStar />
              </span>
              <em></em>
            </div>
          </div>
          <div className="content full-bottom">
            {currentLanguageCode === "tr" ? (
              <h3 className="text-center text-feed">Geri Bildirimde Bulunun</h3>
            ) : (
              <h3 className="text-center text-feed">Give Feedback</h3>
            )}

            <div className="feed-star d-flex justify-content-center flex-column">
              <div className="cont">
                <div className="title">
                  {currentLanguageCode === "tr" ? (
                    <h5>Genel memnuniyetiniz nasıl?</h5>
                  ) : (
                    <h5>What is your overall satisfaction level?</h5>
                  )}
                </div>
                <div className="stars">
                  <form action onSubmit={handleSubmit} ref={form}>
                    <input
                      value={"genel (5) "}
                      className="star star-5"
                      id="star-5"
                      type="radio"
                      name="star"
                      onClick={handleClickGenel}
                    />
                    <label className="star star-5" htmlFor="star-5" />
                    <input
                      value="genel (4) "
                      className="star star-4"
                      id="star-4"
                      type="radio"
                      name="star"
                      onClick={handleClickGenel}
                    />
                    <label className="star star-4" htmlFor="star-4" />
                    <input
                      value="genel (3) "
                      className="star star-3"
                      id="star-3"
                      type="radio"
                      name="star"
                      onClick={handleClickGenel}
                    />
                    <label className="star star-3" htmlFor="star-3" />
                    <input
                      value="genel (2) "
                      className="star star-2"
                      id="star-2"
                      type="radio"
                      name="star"
                      onClick={handleClickGenel}
                    />
                    <label className="star star-2" htmlFor="star-2" />
                    <input
                      value="genel (1) "
                      className="star star-1"
                      id="star-1"
                      type="radio"
                      name="star"
                      onClick={handleClickGenel}
                    />
                    <label className="star star-1" htmlFor="star-1" />
                  </form>
                </div>
              </div>
              <div className="cont">
                <div className="title">
                  {currentLanguageCode === "tr" ? (
                    <h5>Hijyeni nasıl değerlendirirsiniz?</h5>
                  ) : (
                    <h5> How do you evaluate the hygiene?</h5>
                  )}
                </div>
                <div className="stars">
                  <form action>
                    <input
                      value="hijyen(5) "
                      className="star star-5"
                      id="star-5-2"
                      type="radio"
                      name="star"
                      onClick={handleClick}
                    />
                    <label className="star star-5" htmlFor="star-5-2" />
                    <input
                      value="hijyen(4) "
                      className="star star-4"
                      id="star-4-2"
                      type="radio"
                      name="star"
                      onClick={handleClick}
                    />
                    <label className="star star-4" htmlFor="star-4-2" />
                    <input
                      value="hijyen (3)"
                      className="star star-3"
                      id="star-3-2"
                      type="radio"
                      name="star"
                      onClick={handleClick}
                    />
                    <label className="star star-3" htmlFor="star-3-2" />
                    <input
                      value="hijyen (2)"
                      className="star star-2"
                      id="star-2-2"
                      type="radio"
                      name="star"
                      onClick={handleClick}
                    />
                    <label className="star star-2" htmlFor="star-2-2" />
                    <input
                      value="hijyen (1)"
                      className="star star-1"
                      id="star-1-2"
                      type="radio"
                      name="star"
                      onClick={handleClick}
                    />
                    <label className="star star-1" htmlFor="star-1-2" />
                  </form>
                </div>
              </div>
              <div className="cont">
                <div className="title">
                  {currentLanguageCode === "tr" ? (
                    <h5>Yemeklerimizin lezzetini nasıl değerlendirirsiniz?</h5>
                  ) : (
                    <h5>How would you rate the taste of our meals?</h5>
                  )}
                </div>
                <div className="stars">
                  <form action>
                    <input
                      value="lezzet (5)"
                      className="star star-5"
                      id="star-5-3"
                      type="radio"
                      name="star"
                      onClick={handleClickLezzet}
                    />
                    <label className="star star-5" htmlFor="star-5-3" />
                    <input
                      value="lezzet (4)"
                      className="star star-4"
                      id="star-4-3"
                      type="radio"
                      name="star"
                      onClick={handleClickLezzet}
                    />
                    <label className="star star-4" htmlFor="star-4-3" />
                    <input
                      value="lezzet (3)"
                      className="star star-3"
                      id="star-3-3"
                      type="radio"
                      name="star"
                      onClick={handleClickLezzet}
                    />
                    <label className="star star-3" htmlFor="star-3-3" />
                    <input
                      value="lezzet (2)"
                      className="star star-2"
                      id="star-2-3"
                      type="radio"
                      name="star"
                      onClick={handleClickLezzet}
                    />
                    <label className="star star-2" htmlFor="star-2-3" />
                    <input
                      value="lezzet (1)"
                      className="star star-1"
                      id="star-1-3"
                      type="radio"
                      name="star"
                      onClick={handleClickLezzet}
                    />
                    <label className="star star-1" htmlFor="star-1-3" />
                  </form>
                </div>
              </div>
              <div className=" text-center max-width-form">
                {currentLanguageCode === "tr" ? (
                  <label className="text-center" type="text" for="w3review">
                    İsim Soyisim
                  </label>
                ) : (
                  <label className="text-center" type="text" for="w3review">
                    Name Surname
                  </label>
                )}

                <input
                  name="userInfo"
                  type="text"
                  id="userInfo"
                  required
                  onChange={(e) => {
                    setuserInfo(e.target.value);
                  }}
                />
                <label className="text-center" type="text" for="w3review">
                  Email
                </label>
                <input
                  name="from"
                  type="email"
                  id="from"
                  required
                  onChange={(e) => {
                    setFrom(e.target.value);
                  }}
                />
                {currentLanguageCode === "tr" ? (
                  <label className="text-center" for="w3review">
                    Konu
                  </label>
                ) : (
                  <label className="text-center" for="w3review">
                    Subject
                  </label>
                )}

                <input
                  name="subject"
                  type="text"
                  id="subject"
                  required
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
                {currentLanguageCode === "tr" ? (
                  <label className="text-center" for="w3review">
                    Telefon
                  </label>
                ) : (
                  <label className="text-center" for="w3review">
                    Phone
                  </label>
                )}

                <input
                  name="tel"
                  type="number"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
                  id="tel"
                  required
                  onChange={(e) => {
                    setTel(e.target.value);
                  }}
                />
              </div>
              {currentLanguageCode === "tr" ? (
                <label className="text-center" for="w3review">
                  Bize söylemek istediğiniz başka bir şey var mı?
                </label>
              ) : (
                <label className="text-center" for="w3review">
                  Is there anything else you want to tell us?
                </label>
              )}
              <div className="d-flex justify-content-center">
                <textarea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  required
                  id="message"
                  name="message"
                  rows="4"
                  cols="45"
                ></textarea>
              </div>

              <div className=" d-flex justify-content-center">
                {currentLanguageCode === "tr" ? (
                  <button
                    /* onSubmit={handleSubmit} */ value="submit"
                    type="submit"
                    className="feed_btn"
                    onClick={handleClickButton}
                  >
                    Gönder
                  </button>
                ) : (
                  <button
                    /* onSubmit={handleSubmit} */ value="submit"
                    type="submit"
                    className="feed_btn"
                    onClick={handleClickButton}
                  >
                    Send
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

Feedback.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentLanguageCode: state.multilanguage.currentLanguageCode,
});

export default connect(mapStateToProps)(multilanguage(Feedback));
