import axios from "axios";


export const getMenu = async (params) => {
  try {
    const res = await axios.get("https://crownebeta.3wweb.org/menu/read/" + params );
    return res.data;
  } catch (err) {
    return err;
  }
};
