import React, { useState, useEffect } from "react";
import Sheet from "react-modal-sheet";
import { BiArrowBack } from "react-icons/bi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ReactHtmlParser from 'react-html-parser';
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
  currentLanguageCode,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { IoArrowBackCircleOutline } from "react-icons/io5";

import { getMenu } from "../../Api";

const TabComponent = ({ currentLanguageCode, params }) => {
  const [list, setList] = useState([]);
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    const fetchdata = async () => {
      const response = await getMenu(params);
    
      setList(response);
      if(response.length > 0){
      setCurrentTab(response[0].category_tr);
    };
  }
    fetchdata().catch((err) => console.log(err));
  }, [currentLanguageCode]);

  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);




  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };


  return (
    <div className="container">
      <>
        {/* -BAŞLANGIÇ-  YUKARIDAKİ CAROUSEL ŞEKLİNDEKİ TABLAR */}
        <div className="tabs">
          {list.length>0 && list.map((tab, i) => (
            <button
              key={i}
              id={tab.category_tr}
               disabled={currentTab === `${tab._id}`}
              onClick={handleTabClick}
            >
              <img
              crossOrigin="anonymous"
                key={i}
                id={tab.category_tr}
                disabled={currentTab === `${tab._id}`}
                onClick={handleTabClick}
                className="tab_img"
                src={"https://crownebeta.3wweb.org/" + tab.tabImage}
              />
              <span className="font-12">  {currentLanguageCode === "tr" ? (
                                          tab.category_tr
                                        ) : (
                                          tab.category_en
                                        )}</span>
            </button>
          ))}
        </div>
        {/* -SON- YUKARIDAKİ CAROUSEL ŞEKLİNDEKİ TABLAR */}

        {/* -BAŞLANGIÇ- LİSTELENEN ÜRÜNLER */}
        <div className="content">
          {list.length > 0  && list.map((tab, i) =>
            tab.category_tr === currentTab ? (
              <div key={i}>
                <div>
                  <>
                    <>
                      {/* <p className="title">{tab.description_tr}</p> */}
                     
                      <div className="center_category_title">
                        {" "}
                        {/* -BAŞLANGIÇ- KATEGORİ BAŞLIĞI */}
                        <h5> {currentLanguageCode === "tr" ? (
                                          tab.category_tr
                                        ) : (
                                          tab.category_en
                                        )}</h5>
                        {/* -SON- KATEGORİ BAŞLIĞI */}
                      </div>
                      <ul className="menu-list">
                        {tab.category_tr === currentTab
                          ? tab.items.map((item, j) =>
                              item.subcategory_tr == null ? (
                                <>
                                  <li
                                    className="d-flex"
                                    key={j}
                                    onClick={() => handleItemClick(item)}
                                  >
                                    <div className="category_inner">
                                      <span className="color-black">
                                        {currentLanguageCode === "tr" ? (
                                          item.name_tr
                                        ) : (
                                          item.name_en
                                        )}
                                        <span>
                                          {item.allergens.length > 0 &&
                                            item.allergens.map((allergen, k) => {
                                              if (allergen.isChecked)
                                                return (
                                                  <img
                                                    key={k}
                                                    style={{
                                                      maxWidth: "1.5rem",
                                                      display: "inline-block",
                                                      marginLeft: "0.2rem",
                                                    }}
                                                    src={allergen.imgUrl}
                                                    alt={allergen.icon}
                                                    title={allergen.icon}
                                                  />
                                                );
                                            })}
                                        </span>
                                      </span>
                                      <span className="category_inner">
                                        
                                        {currentLanguageCode === "tr" ? (
                                          ReactHtmlParser(item.description_tr)
                                        ) : (
                                          ReactHtmlParser(item.description_en)
                                        )}
                                      </span>
                                      <p className="item_price">{item.price}</p>
                                    </div>
                                    <span className="color-black">
                                      <img
                                        crossOrigin="anonymous"
                                        className="category_img"
                                        src={
                                          "https://crownebeta.3wweb.org" +
                                          item.image
                                        }
                                        alt={currentLanguageCode === "tr" ? (
                                          item.name_tr
                                        ) : (
                                          item.name_en
                                        )}
                                      />
                                    </span>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <Accordion className="accordion_one">
                                    <AccordionSummary>
                                      <h6 className="wine_title">
                                        {" "}
                                        {currentLanguageCode === "tr" ? (
                                          item.subcategory_tr
                                        ) : (
                                          item.subcategory_en
                                        )}
                                      </h6>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {item.products.map(
                                        (product, productIndex) => {
                                          return (
                                            <>
                                              <li
                                                className="d-flex border"
                                                key={productIndex}
                                                onClick={() =>
                                                  handleItemClick(product)
                                                }
                                              >
                                                <div className="category_inner">
                                                  <span className="color-black">
                                                  {currentLanguageCode === "tr" ? (
                                          product.name_tr
                                        ) : (
                                          product.name_en
                                        )}
                                                  </span>
                                                  <span className="category_inner">
                                                      {currentLanguageCode === "tr" ? (
                                          product.description_tr
                                        ) : (
                                          product.description_en
                                        )}
                                                  </span>
                                                  <p className="item_price">
                                                    {product.price}
                                                  </p>
                                                </div>
                                                <span className="color-black">
                                                  <img
                                                    crossOrigin="anonymous"
                                                    className="category_img"
                                                    src={
                                                      "https://crownebeta.3wweb.org" +
                                                      product.image
                                                    }
                                                    alt= {currentLanguageCode === "tr" ? (
                                                      product.name_tr
                                                    ) : (
                                                      product.name_en
                                                    )}
                                                  />
                                                </span>
                                              </li>
                                            </>
                                          );
                                        }
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </>
                              )
                            )
                          : null}
                      </ul>
                    </>
                    {selectedItem && (
                      <Sheet isOpen={isOpen} onClose={handleClose}>
                        <div className="d-flex justify-content-center">
                          <IoArrowBackCircleOutline className="back_icon_qr" />
                          <img
                            className="modal_img_category"
                            crossOrigin="anonymous"
                            src={
                              "https://crownebeta.3wweb.org" +
                              selectedItem.image
                            }
                          />
                        </div>
                        <Sheet.Container>
                          <Sheet.Header />
                          <Sheet.Content>
                            <div className="modal_detail">
                              <h2 className="modal_detail_h2">
                                {currentLanguageCode === "tr" ? (
                                  selectedItem.name_tr
                                ) : (
                                  selectedItem.name_en
                                )}
                                <span className="color-black">
                                  {selectedItem.allergens.length > 0 &&
                                    selectedItem.allergens.map((allergen, q) => {
                                      if (allergen.isChecked)
                                        return (
                                          <img
                                            key={q}
                                            style={{
                                              maxWidth: "1.5rem",
                                              display: "inline-block",
                                              marginLeft: "0.2rem",
                                            }}
                                            src={allergen.imgUrl}
                                            alt={allergen.icon}
                                            title={allergen.icon}
                                          />
                                        );
                                    })}
                                </span>
                              </h2>
                              <p className="modal_detail_desc">
                                {currentLanguageCode === "tr" ? (
                                  selectedItem.description_tr
                                ) : (
                                  selectedItem.description_en
                                )}
                              </p>
                              <p className="modal_detail_price">
                                {selectedItem.price}
                              </p>
                            </div>
                          </Sheet.Content>
                        </Sheet.Container>
                        <Sheet.Backdrop onClick={handleClose}>
                          <button className="back-button" onClick={handleClose}>
                            <BiArrowBack className="back_btn_icon" />
                          </button>
                        </Sheet.Backdrop>
                      </Sheet>
                    )}
                  </>
                </div>
              </div>
            ) : null
          )}
        </div>
        {/* -SON- LİSTELENEN ÜRÜNLER */}
      </>
    </div>
  );
};

TabComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  params: PropTypes.string,
};
export default connect()(multilanguage(TabComponent));
