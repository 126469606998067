import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import "../App.css";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TabComponent from "../components/TabComponents/TabComponent";
import { useLocation } from 'react-router-dom';
const Lobby = ({ currentLanguageCode,dispatch }) => {
  const [expandedPanels, setExpandedPanels] = useState({});
  const location = useLocation()
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prevExpandedPanels) => ({
      ...prevExpandedPanels,
      [panel]: isExpanded,
    }));
  };
  useEffect(()=>{
    if(location.hash=="#en"){
    dispatch(changeLanguage("en"));
  }else{
    dispatch(changeLanguage("tr"));

  }
  },[location.hash])

  return (
    <div>
      <div className="body-bg" />
      <Header />
      <div className="page-content header-clear-large">
        <div className="page-content-scroll">
          <div className="content">
            <div className="decoration-fancy">
              <strong />
              <span>
                <img
                  className="w-100"
                  src="/aura.png"
                  alt="Aura Restaurant"
                />

              
              </span>
              <em></em>
            </div>
          </div>

          <div className="content full-bottom"> 
            <Accordion
              className="mb-2 accordion_one"
              expanded={expandedPanels["panel1"]} // Use the corresponding property from expandedPanels
              onChange={handleChange("panel1")}
            >
              <AccordionSummary>
                {" "}
                {currentLanguageCode === "tr" ? (
                  <h4> ÖN BİLGİLENDİRME</h4>
                ) : (
                  <h4> PRELIMINARY INFORMATION</h4>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {currentLanguageCode === "tr" ? (
                  <p>
                    Bu menüdeki yiyecekler, 11:00 ile 22:30 saatleri arasında
                    servis edilmektedir. Fiyatlar Türk Lirası ve KDV dahildir.
                    Menümüzde yer alan bazı ürünlerimiz, alerjen madde
                    içerebilirler veya sizin alerjiniz olan bir maddenin içeri
                    ğine sahip olabilirler, lütfen detaylı bilgi almak ve
                    diyetsel ihtiyaçlarınızı belirtmek için servis personelinize
                    danışınız.
                  </p>
                ) : (
                  <p>
                    Food on this menu is served between 11:00 and 22:30 is
                    served. Prices include Turkish Lira and VAT. Some of our
                    products in our menu, allergen substance They may contain or
                    contain a substance to which you are allergic. They may have
                    a diet, please get detailed information and diet. Consult
                    your service personnel to specify your needs. your present.
                  </p>
                )}

                {currentLanguageCode === "tr" ? <h5>Çocuk</h5> : <h5>Kids</h5>}
                {currentLanguageCode === "tr" ? (
                  <p>
                    Yiyeceklerin hazırlığında kullanılan yağ ve tuz oranları
                    çocuk sağlığı ve gelişimine uygun düzeydedir. Herhangi bir
                    gıdaya karşı alerjiniz veya duyarlılığınız varsa lütfen
                    garsonunuza belirtiniz.
                  </p>
                ) : (
                  <p>
                    Fat and salt ratios used in the preparation of food level of
                    health and development. to any food If you have allergies or
                    sensitivities to Please specify.
                  </p>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="mb-2 accordion_two"
              expanded={expandedPanels["panel2"]} // Use the corresponding property from expandedPanels
              onChange={handleChange("panel2")}
            >
              <AccordionSummary>
                {currentLanguageCode === "tr" ? (
                  <h4> ALERJENLER</h4>
                ) : (
                  <h4> ALLERGENS</h4>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/vejeterjan.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">
                        Vejetaryen beslenmeye uygundur
                      </p>
                    ) : (
                      <p className="alergens_p">Suitable for vegetarian diet</p>
                    )}
                  </div>
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/alkol.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">
                        İçeriğinde alkol bulunmaktadır
                      </p>
                    ) : (
                      <p className="alergens_p">Contains alcohol</p>
                    )}
                  </div>
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/kuruyemis.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">
                        İçeriğinde kuruyemiş bulunmaktadır
                      </p>
                    ) : (
                      <p className="alergens_p">It contains nuts</p>
                    )}
                  </div>
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/glutensiz.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">Glutensiz ürünler</p>
                    ) : (
                      <p className="alergens_p">Gluten-free</p>
                    )}
                  </div>
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/acibiber.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">Acı biberli</p>
                    ) : (
                      <p className="alergens_p">Spicy</p>
                    )}
                  </div>
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/saglikli.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">Sağlıklı yiyecekler</p>
                    ) : (
                      <p className="alergens_p">Healty Foods</p>
                    )}
                  </div>
                  <div className="alergens">
                      <img style={{maxWidth:"1.5rem", display:"inline-block",  marginLeft:"0.2rem"  }} src="/assets/icon/vegan.svg"  />
                    {currentLanguageCode === "tr" ? (
                      <p className="alergens_p">Vegan beslenmeye uygundur</p>
                    ) : (
                      <p className="alergens_p">Suitable for vegan diet</p>
                    )}
                  </div>{" "}
                  {currentLanguageCode === "tr" ? (
                    <p className="mt-2">
                      Şarap, köpüren şarap veya şampanya talepleriniz için
                      lütfen oda telefonunuzdan Room Servis tuşuna basarak bize
                      ulaşınız.
                    </p>
                  ) : (
                    <p className="mt-2">
                      For your wine, sparkling wine or champagne requests,
                      please Contact us by pressing the Room Service button from
                      your room phone.
                    </p>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
            <TabComponent params={"aura"} />
          </div>
        </div>
      </div>
    </div>
  );
};

Lobby.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentLanguageCode: state.multilanguage.currentLanguageCode,
});

export default connect(mapStateToProps)(multilanguage(Lobby));
